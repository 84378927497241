import React, { useContext, useEffect, useState } from 'react'
import DeliveryOptionItem from './DeliveryOptionItem'
import { Context } from '../..'
import UserServices from '../../services/general/userServices'
import { observer } from 'mobx-react-lite'
import { toast } from 'react-toastify'

const DeliveryOption = observer(({setDelivery}) => {
    const {app} = useContext(Context);

    const [deliveries, setDeliveries] = useState();
    const [loading, setLoading] = useState();

    const fetchDeliveries = async () => {
        setLoading(true);
        
        const res = await UserServices.cart.order.deliveries();

        if(res.statusCode === 200){
            setDeliveries(res.content)
        }else{
            toast.error(res.message)
        }

        setLoading(false);
    }

    useEffect(() => {
        fetchDeliveries();
    }, [app.branchString])

  return (
    <>
        <div className="checkout-icon">
            <i className='fa-solid fa-cart-shopping'/>
        </div>
        <div className="checkout-box">
            <div className={loading ? "checkout-title isLoading isLoading-mini" : "checkout-title"}>
                <h4>{app.localizationsItems?.delivery_variant} <span className='red'>*</span></h4>
            </div>

            <div className="checkout-detail">
                <div className="accordion accordion-flush custom-accordion">
                    {deliveries?.map((card, index) =>
                        <DeliveryOptionItem card={card} setDelivery={setDelivery} key={index}/>
                    )}
                </div>
            </div>
        </div>
    </>
  )
})

export default DeliveryOption