import React, { useContext, useEffect, useState } from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import UserServices from '../../services/general/userServices'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'

const DeliveryPoints = observer(() => {
    const {app} = useContext(Context);

    const {register, formState: {errors}} = useFormContext();

    const [pickupAddresses, setPickupAddresses] = useState();
    const [loading, setLoading] = useState();

    const fetchPickups = async () => {
        setLoading(true);
        
        const res = await UserServices.cart.order.pickupAddresses();

        if(res.statusCode === 200){
            setPickupAddresses(res.content)
        }else{
            toast.error(res.message)
        }

        setLoading(false);
    }

    useEffect(() => {
        fetchPickups();
    }, [app.branchString])

  return (
    <>
        <div className="checkout-icon">
            <i className='fa-solid fa-location-dot'/>
        </div>
        <div className="checkout-box">
            <div className={loading ? "checkout-title isLoading isLoading-mini" : "checkout-title"}>
                <h4>{app.localizationsItems?.delivery_points}</h4>
            </div>

            <div className="checkout-detail">
                <div className="accordion accordion-flush custom-accordion">
                    {pickupAddresses?.items?.map((card, index) =>
                        <div className="accordion-item" key={index}>
                            <div className="accordion-header">
                                <div className="accordion-button">
                                    <div className="custom-form-check form-check mb-0">
                                        <label className="form-check-label">
                                            <input className="form-check-input mt-0" required value={card.id} type="radio" 
                                                {...register('address_id', {required: true})}
                                            />
                                            {card.title}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </>
  )
})

export default DeliveryPoints