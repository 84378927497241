import React, { useContext, useEffect, useState } from 'react'
import SelectCityModal from '../modals/SelectCityModal';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import AppServices from '../../services/general/appServices';

const SelectCity = observer(() => {
    const {app} = useContext(Context);
    const [openModal, setOpenModal] = useState();
    const [branch, setBranch] = useState();

    const [branches, setBranches] =useState();

    const fetchCities = async () => {
        const res = await AppServices.locations();
        if(res.statusCode === 200){
            setBranches(res.content.items);
            const tempBranch = { 
                value: res.content.items[0]?.id, 
                label: res.content.items[0]?.name, 
                location: res.content.items[0]?.value 
            };
            app.setBranch(tempBranch);
            setBranch(tempBranch);
            if(res.content.items?.length <= 0){
                localStorage.removeItem('branch');
            }
        }else{
            localStorage.removeItem('branch');
        }
    }

    useEffect(() => {
        fetchCities();
    }, [])

    useEffect(() => {
        if(branches && typeof(branches) == "object"){
            let localTemp = localStorage.getItem('branch');
            try{
                let temp = JSON.parse(localTemp);
                let tempOpenModal = true;
                for (const city in branches) {
                    const branchesList = branches[city];
                    if (branchesList?.find(it => it.id == temp?.value)) {
                        app.setBranch(temp);
                        setBranch(temp);
                        tempOpenModal = false;
                        break;
                    } else if (branches[city]) {
                        const tempBranch = { 
                            value: branches[city][0]?.id, 
                            label: branches[city][0]?.name, 
                            location: branches[city][0]?.city?.value 
                        };
                        app.setBranch(tempBranch);
                        setBranch(tempBranch);
                    }
                }
                if(tempOpenModal){
                    setOpenModal(true)
                }
            }catch(e){
                if(Object.values(branches)[0]){
                    let tempBranch = Object.values(branches)[0][0];
                    if(tempBranch){
                        let modifyBranch = { value: tempBranch?.id, label: tempBranch?.name, location: tempBranch?.city?.value }
                        modifyBranch && app.setBranch(modifyBranch);
                        modifyBranch && setBranch(modifyBranch);
                        setOpenModal(true)
                    }
                }
            }
        }
    }, [branches])

  return (
    branches &&
    <>
        <div className='select-city'>
            <i className='fa fa-map-marker'></i>
            {branch?.label}
        </div>
        {openModal && 
            <SelectCityModal 
                show={openModal} 
                setShow={setOpenModal} 
                setBranch={setBranch}
                branches={branches}
            />
        }
    </>
  )
})

export default SelectCity