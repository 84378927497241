import React, { useEffect } from 'react';

const GoogleTranslateWidget = () => {
    useEffect(() => {
        const scriptId = 'google-translate-script';

        const addGoogleTranslateScript = () => {
            return new Promise((resolve, reject) => {
                if (document.getElementById(scriptId)) {
                    resolve();
                    return;
                }
                const script = document.createElement('script');
                script.id = scriptId;
                script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
                script.async = true;
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
            });
        };

        const googleTranslateElementInit = () => {
            try {
                new window.google.translate.TranslateElement(
                    { pageLanguage: 'ru', includedLanguages: 'en,es,kk,fr,de,zh-CN,ru', layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE },
                    'google_translate_element'
                );
            } catch (e) {
                console.error('Google Translate initialization error:', e);
            }
        };

        window.googleTranslateElementInit = googleTranslateElementInit;

        addGoogleTranslateScript().catch(e => {
            console.error('Failed to load Google Translate script:', e);
        });

        return () => {
            delete window.googleTranslateElementInit;
            const script = document.getElementById(scriptId);
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, []);

    return (
        <div id="google_translate_element"></div>
    );
};

export default GoogleTranslateWidget;
