import React, { useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const SelectCityModal = observer(({
    show, setShow, setBranch,
    branches
}) => {
    const {app} = useContext(Context);

    const handleClose = () => setShow(false);

    const {handleSubmit, register, setValue} = useForm();

    useEffect(() => {
        if(localStorage.getItem('branch')){
            setValue('branch', localStorage.getItem('branch'))
        }
    }, [])

    const CustomSubmit = (data) => {
        const selectedBranch = JSON.parse(data.branch);
        app.setBranch(selectedBranch)
        setBranch(selectedBranch)
        setShow(false)
    };

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <form className='form-modal form-check' onSubmit={handleSubmit(CustomSubmit)}>
                <h2 className='mb-4'>{app.localizationsItems?.select_city}</h2>
                {Object.entries(branches)?.map(([key, value]) =>
                    <div className="row" key={key}>
                        <h4>{key}</h4>
                        {Object.entries(value).map(([key, card]) => (
                            <div className="col-6" key={card.id}>
                                <label className="form-check-label">
                                    <input 
                                        {...register('branch')} 
                                        value={JSON.stringify({ value: card.id, label: card.name, location: card.city?.value })} 
                                        className="form-check-input mt-0" 
                                        type="radio"
                                    />
                                    {card.name}
                                </label>
                            </div>
                        ))}
                    </div>
                )}
                <div className='row justify-content-end'>
                    <div className="col-auto">
                        <div className='btn btn-light shopping-button text-dark' onClick={() => setShow(false)}>{app.localizationsItems?.cancel}</div>
                    </div>
                    <div className="col-auto">
                        <button className='btn btn-animation fw-bold ms-auto'>{app.localizationsItems?.save}</button>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default SelectCityModal