import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useContext } from 'react';
import { Context } from '../..';
import CatalogServices from '../../services/general/catalogServices';
import { observer } from 'mobx-react-lite';

const CatalogTop = observer(() => {
    const {app} = useContext(Context);
    const {slug} = useParams();

    const [breadcrumbs, setBreabcrumbs] = useState();

    const fetchCategory = async() => {
        const res = await CatalogServices.categories.view(slug);
        
        if(res.statusCode === 200){
            setBreabcrumbs(res.content?.breadcrumbs)
        }
    }

    useEffect(() => {
        if(slug){
            fetchCategory()
        }
    }, [slug])

  return (
	<section className="breadscrumb-section pt-0 mb-4">
		<div className="container-fluid-lg">
			<div className="row">
				<div className="col-12">
                    <div className="breadscrumb-contain">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <NavLink to={`/${app.lang}`}>
                                    <i className="fa-solid fa-house"></i>
                                </NavLink>
                            </li>
                            <li className="breadcrumb-item">
                                <NavLink to={`/${app.lang}/catalog`}>
                                    {app.localizationsItems?.catalog}
                                </NavLink>
                            </li>
                            {breadcrumbs?.map((card, index) =>
                                <li className="breadcrumb-item active" aria-current="page" key={index}>
                                    <NavLink to={`/${app.lang}/catalog/${card.slug}`}>
                                        {card.title}
                                    </NavLink>
                                </li>
                            )}
                        </ol>
                    </div>
				</div>
                <div className="col-12">
                    <h2>{app.localizationsItems?.catalog}</h2>
                </div>
			</div>
		</div>
	</section>
  )
})

export default CatalogTop