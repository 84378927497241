import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import img from "../../images/placeholder.png"
import { NavLink, useNavigate } from 'react-router-dom';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const MainBanner = observer(({item}) => {
    const {app} = useContext(Context);

    const navigate = useNavigate();

	const [banner1, setBanner1] = useState();
	const [banner2, setBanner2] = useState();
	const [banner3, setBanner3] = useState();
	const [banner4, setBanner4] = useState();

	useEffect(() => {
		if(item){
			setBanner1(item.elements[0]);
			setBanner2(item.elements[1]);
			setBanner3(item.elements[2]);
			setBanner4(item.elements[3]);
		}
	}, [item])

  return (
	<section className="home-section-2 home-section-small section-b-space"
        style={{backgroundImage: `url(${item?.preview_image})`}}
    >
		<div className="container-fluid-lg">
			<div className="row g-4">
				{banner1 &&
					<div className="col-xxl-6 col-md-8">
						<div className="home-contain h-100" onClick={() => banner1.link && navigate(banner1.link)}>
							<img src={banner1.image ? banner1.image : img} className="img-fluid bg-img position-absolute" alt=""
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                            />
							<div className="home-detail home-width p-center-left position-relative">
								<div style={{color: banner1.color}}>
									<h6 className="ls-expanded theme-color" dangerouslySetInnerHTML={{__html: banner1.subtitle_top}}></h6>
									<h1 className="fw-bold w-100" dangerouslySetInnerHTML={{__html: banner1.title}}></h1>
									<h3 className="text-content fw-light" dangerouslySetInnerHTML={{__html: banner1.subtitle}}></h3>
									<p className="d-sm-block d-none" dangerouslySetInnerHTML={{__html: banner1.description}}></p>
									{banner1.button_text &&
                                        <button onClick={() => navigate(banner1.link)} className="btn bg-white theme-color mt-3 home-button btn-2">
                                            {banner1.button_text}
                                        </button>
                                    }
								</div>
							</div>
						</div>
					</div>
				}

				{banner2 &&
					<div className="col-xxl-3 col-md-4 ratio_medium d-md-block d-none">
						<div className="home-contain home-small h-100" onClick={() => banner2.link && navigate(banner2.link)}>
							<div className="h-100">
								<img src={banner2.image ? banner2.image : img} className="img-fluid bg-img" alt=""
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; 
                                        currentTarget.src = img
                                    }}
                                />
							</div>
							<div className="home-detail text-center p-top-center w-100 text-white">
								<div style={{color: banner2.color}}>
									<h4 className="fw-bold" dangerouslySetInnerHTML={{__html: banner2.title}}></h4>
									<h5 className="text-center" dangerouslySetInnerHTML={{__html: banner2.subtitle}}></h5>
									{banner2.button_text &&
                                        <button onClick={() => navigate(banner2.link)} className="btn bg-white theme-color mt-3 home-button mx-auto btn-2">
                                            {banner2.button_text}
                                        </button>
                                    }
								</div>
							</div>
						</div>
					</div>
				}
				<div className="col-xxl-3 ratio_65 d-xxl-block d-none">
					<div className="row g-3 banners-height">
						{banner3 &&
							<div className="col-xxl-12 col-sm-6 banner-itm">
								<div className="home-contain" onClick={() => banner3.link && navigate(banner3.link)}>
									<NavLink to={banner3.link}>
										<img src={banner3.image ? banner3.image : img} className="img-fluid bg-img" alt=""
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; 
                                                currentTarget.src = img
                                            }}
                                        />
									</NavLink>
									<div className="home-detail text-white w-50 p-center-left home-p-sm">
										<div style={{color: banner3.color}}>
											<h4 className="fw-bold" dangerouslySetInnerHTML={{__html: banner3.title}}></h4>
											<h5 dangerouslySetInnerHTML={{__html: banner3.subtitle}}></h5>
                                            {banner3.button_text &&
                                                <button onClick={() => navigate(banner3.link)} className="btn bg-white theme-color mt-3 home-button mx-auto btn-2">
                                                    {banner3.button_text}
                                                </button>
                                            }
										</div>
									</div>
								</div>
							</div>
						}

						{banner4 && 
							<div className="col-xxl-12 col-sm-6 banner-itm">
								<div className="home-contain" onClick={() => banner4.link && navigate(banner4.link)}>
									<NavLink to={banner4.link}>
										<img src={banner4.image ? banner4.image : img} className="img-fluid bg-img" alt=""
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; 
                                                currentTarget.src = img
                                            }}
                                        />
									</NavLink>
									<div className="home-detail text-white w-50 p-center-left home-p-sm">
										<div style={{color: banner4.color}}>
											<h4 className="fw-bold" dangerouslySetInnerHTML={{__html: banner4.title}}></h4>
											<h5 dangerouslySetInnerHTML={{__html: banner4.subtitle}}></h5>
										</div>
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	</section>
  )
})

export default MainBanner